import { Subscription } from 'rxjs';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
7;

@Component({
  selector: 'app-confirm-loan',
  templateUrl: './confirm-loan.component.html',
  styleUrls: ['./confirm-loan.component.css'],
})
export class ConfirmLoanComponent implements OnInit, OnDestroy {
  user;
  interest;
  totalDeduction;
  monthlyDeduction;
  getUser$: Subscription;
  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<ConfirmLoanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.getUser$ = this.userService
      .getUser(this.userService.user.lp_number)
      .subscribe({
        next: (res: any) => {
          this.user = res.user;
        },
      });
    this.interest = (Number(this.data.amount) * 0.07).toFixed(2);
    this.totalDeduction = (
      Number(this.data.amount) + Number(this.interest)
    ).toFixed(2);
    this.monthlyDeduction = (
      Number(this.totalDeduction) / Number(this.data.payment_duration)
    ).toFixed(2);
  }

  ngOnDestroy(): void {
    this.getUser$.unsubscribe();
  }
}
