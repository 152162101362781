import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Borrow } from 'src/app/models/borrow';

@Component({
  selector: 'app-borrow',
  templateUrl: './borrow.component.html',
  styleUrls: ['./borrow.component.css']
})
export class BorrowComponent implements OnInit {
  @Input() loan;
  @Output() delete: EventEmitter<Borrow> = new EventEmitter<Borrow>();
  constructor() { }

  ngOnInit(): void {  }

  deleteRequest(event){
   this.delete.emit(this.loan)
  }
}
