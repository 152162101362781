import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError, map } from 'rxjs/operators';
import { JwtInterceptor } from '@auth0/angular-jwt';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {

  }
    // tslint:disable-next-line: typedef
    handleError(error: HttpErrorResponse){

        return throwError(error);
      }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

      const authService = this.injector.get(AuthService);
      const token = authService.getToken();
      request = request.clone({

       headers: request.headers.set('Authorization', `Bearer ${token}`)

    });
      return next.handle(request)
      .pipe(
        map((event: HttpEvent<any>) => {
        return event;
      }),
        catchError(this.handleError)
      );
  }
}
