import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-payback',
  templateUrl: './payback.component.html',
  styleUrls: ['./payback.component.css']
})
export class PaybackComponent implements OnInit {
  @Input() payback;
  constructor() { }

  ngOnInit(): void {
  }

}
