import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {

  transform(list: any[], string: 'updatedAt'): any {

  return list.sort(function (a, b) {
    list = list || [];
    return <any>new Date(b.updatedAt) - <any>new Date(a.updatedAt);
  })
}

}
