import {
  Component,
  Input,
  OnInit,
  DoCheck,
  AfterViewChecked,
  OnDestroy,
} from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Observable, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
})
export class SignInComponent implements OnInit, OnDestroy {
  hide = true;
  loadingButton: boolean;
  error;
  status;
  signin$: Subscription;
  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadingButton = false;
    const today = new Date();
    const currentTime = today.getHours();

    if (currentTime < 12) {
      this.status = 'Morning';
    } else if (currentTime < 18) {
      this.status = 'Afternoon';
    } else {
      this.status = 'Evening';
    }
  }

  onSubmit(f: NgForm): void {
    this.loadingButton = true;
    this.signin$ = this.authService
      .signin(f.value)
      .pipe(finalize(() => (this.loadingButton = false)))
      .subscribe({
        next: (res) => {
          const token = res.token;
          this.loadingButton = false;
          if (!token) {
            this.snackBar.open(`${res.message}`, 'Sorry!', {
              duration: 2000,
            });
            this.router.navigateByUrl(`/`);
          } else {
            sessionStorage.setItem('token', token);
            this.authService.user = this.authService.getPayLoad();
            this.authService.lp_number = this.authService.user.lp_number;
            this.snackBar.open(
              `Welcome LP${this.authService.lp_number}`,
              'Ok!',
              {
                duration: 2000,
              }
            );
            this.router.navigateByUrl(`/user/${this.authService.lp_number}`);
          }
        },
        error: (err) => {
          this.loadingButton = false;
          this.error = 'Wrong LP/Password';
          this.snackBar.open('Wrong LP/Password', 'Error!', {
            duration: 2000,
          });
        },
      });
  }

  ngOnDestroy(): void {
    if (this.signin$) {
      this.signin$.unsubscribe();
    }
  }
}
