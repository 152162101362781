import { Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-deduction-form',
  templateUrl: './deduction-form.component.html',
  styleUrls: ['./deduction-form.component.css'],
})
export class DeductionFormComponent implements OnInit, OnDestroy {
  createDeduction$: Subscription;
  constructor(
    private dialogRef: MatDialogRef<DeductionFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adminService: AdminService
  ) {}

  ngOnInit(): void {}

  ngSubmit(e) {
console.log(e);

    this.createDeduction$ = this.adminService
      .createSpecialDeduction({
        loan: this.data,
        amount: Number(e.value.amount)
      })
      .subscribe({
        next: (res) => {
          console.log(res);
        },
        error: (err) => console.log(err.error),
      });
  }

  ngOnDestroy(): void {
    if (this.createDeduction$) {
      this.createDeduction$.unsubscribe();
    }
  }
}
