<mat-card>
<mat-card-title class="space-between">
<span>LP{{loan.lp_number}}</span>
<span>{{loan.amount | currency:'NGN':'symbol-narrow'}}</span>
</mat-card-title>

<mat-card-content>
  <span>{{loan.name}}</span>
  <p><mat-checkbox [checked]="loan.guarantor1_confirmed" [disabled]>LP{{loan.guarantor1_lp}}</mat-checkbox></p>
  <p><mat-checkbox [checked]="loan.guarantor2_confirmed" [disabled]>LP{{loan.guarantor2_lp}}</mat-checkbox></p>
</mat-card-content>
<mat-card-footer>

<span>{{loan.createdAt |date:'medium' }}</span>
<button mat-button [disabled]="loan.cofnirmed" (click)="deleteRequest($event)" color="warn">Delete</button>
</mat-card-footer>
</mat-card>
