import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
})
export class SignUpComponent implements OnInit, OnDestroy {
  isLinear = false;

  officeDetails;
  preview;
  personalDetails;
  bankDetails;
  passwordDetails;
  nextOfKinDetails;
  loading = false;

  signup$: Subscription;


  constructor(
    private router: Router,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {




  }

  signUpForm = this.formBuilder.group({
    firstName: ['',[Validators.required, Validators.minLength(3)] ],
    lastName: ['', [Validators.required, Validators.minLength(3)]],
    middleName: ['', [Validators.minLength(3)]],
    email: ['', [Validators.required, Validators.email]],
    phone_number: ['', [Validators.required, Validators.minLength(11)]],
    lp_number: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(7)]],
    office: ['', Validators.required],
    location: ['', Validators.required],
    account_number:['', Validators.required],
    bank:['', Validators.required],
    password: ['', Validators.required],
    confirmpassword: ['', Validators.required],
    next_kin: ['', Validators.required],
    kin_phone: ['', Validators.required],


  });


  onSubmit(): void {
    this.loading = true;
    this.signup$ = this.authService.signup(this.signUpForm.value).subscribe({
      next: (res: any) => {
        const message = res.message;
        this.snackbar.open(message , 'Ok!', {
          duration: 2000,
        });
        this.router.navigateByUrl(`/`);
      },
      error: (err) => {
        this.loading = false;
        this.snackbar.open('User profile not created', 'Sorry!', {
          duration: 2000,
        });
      },
    });
  }

  ngOnDestroy(): void {
    if (this.signup$) {
      this.signup$.unsubscribe();
    }
  }
}
