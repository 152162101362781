import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(list: any[], searchItem: string): any {


    return list
      ? list
      .filter(
          (item: any) =>
            item.lp_number.match(String(searchItem)) ||  String(item.amount).match(String(searchItem))
        )
      : [];
  }
}



