import { UserService } from 'src/app/services/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  //private API = 'http://localhost:5000/api';
  private API = 'https://cooperativebox.herokuapp.com/api';
  user;
  lp_number;
  constructor(
    private http: HttpClient,
    private router: Router,
    private helper: JwtHelperService
  ) {}

  signup(data) {
    return this.http.post(`${this.API}/register`, data);
  }

  signin(data) {
    return this.http.post<{ token: string; message: string }>(
      `${this.API}/login`,
      data
    );
  }

  signout(): void {
    this.router.navigateByUrl('/');
    this.http.get(`${this.API}/user/logout`).subscribe({
      next: (res) => {
      sessionStorage.removeItem('token');
    }});
  }

  isAdmin(): boolean {
    return this.user.role === 'ADMIN' ? true : false;
  }

  isLoggedIn(): boolean {
    const token = this.getToken();
    return !this.helper.isTokenExpired(token) ? true : false;
  }
  getToken(): string {
    return sessionStorage.getItem('token');
  }

  getPayLoad(): object {
    const token = this.getToken();
    const user = this.helper.decodeToken(token);
    return user;
  }

  getUser(lp_number) {
    return this.http.get(`${this.API}/${lp_number}`);
  }

  adminSignin(data) {
    return this.http.post<{ token: string }>(`${this.API}/admin/login`, data);
  }
}
