<app-navbar></app-navbar>

<section class='signin'>
<!-- <section>
  Welcome to WAEC Ikeja Contribution Thrift & Society.
  Ikeja, Ogba, Lagos.

  Save your future by joining us, with just a click to sign up.

  Accountability
  Secured future


</section> -->

<!-- <h1>WAEC Ikeja Co-operative Thrift  & Credit Society<span class="underscore">&#95;</span></h1> -->


  <mat-card>
    <mat-card-title>
      Welcome back,
    </mat-card-title>
    <form class='signform' #signin='ngForm' (ngSubmit)="onSubmit(signin)">
      <mat-card-content>{{ error }}</mat-card-content>
      <mat-label>LP Number</mat-label>
      <mat-form-field appearance="outline">

        <input type='text' name='lp_number' matInput placeholder="12345" required [(ngModel)]='signin.lp_number'
          #lp_number='ngModel'>
        <mat-hint>1234</mat-hint>
        <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
     -->
      </mat-form-field>
      <mat-label>Password</mat-label>
      <mat-form-field appearance="outline">

        <input [type]="hide ? 'password' : 'text'" name='password' matInput placeholder="Password" required
          [(ngModel)]='signin.password' #password='ngModel'>
        <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
          <mat-icon matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-hint>Six characters or more</mat-hint>
        <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
      -->
      </mat-form-field>

      <button class="btn-underline" mat-raised-button color="primary" [disabled]='!signin.form.valid' type='submit'>
        Sign in
        <mat-progress-spinner *ngIf="loadingButton" color="warn" diameter="20" strokeWidth="2" mode="indeterminate">
        </mat-progress-spinner>
      </button>
    </form>
    <p>Are you a new user? Click here to <a mat-button routerLink='../signup' color='accent'>Sign Up</a>.</p>
  </mat-card>

</section>
