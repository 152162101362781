import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Save } from 'src/app/models/save';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminService } from 'src/app/services/admin.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-save',
  templateUrl: './save.component.html',
  styleUrls: ['./save.component.css']
})
export class SaveComponent implements OnInit {
  @Input() save: Save;
  @Output() emitter = new EventEmitter<Save>();
  deleteSave$: Subscription;

  constructor(private userService: UserService, private matsnackbar: MatSnackBar) {

   }

  ngOnInit(): void {

  }


  deleteSave(){
    this.deleteSave$ = this.userService.deleteSave(this.save.save_id).subscribe({
      next: (res) => {
        this.matsnackbar.open('Save deleted successfully', 'Ok!', {
          duration: 2000,
        });
      },
      error: (err) => {
      }
    })
    this.ngOnInit();
  }


  onDestroy(){
    if(this.deleteSave$){
      this.deleteSave$.unsubscribe();
    }
  }


}


