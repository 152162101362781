import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit, OnDestroy {
  hide = true;
  loading = false;
  error;
  adminSignin$: Subscription;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {}

  adminSubmit(f: NgForm): void {
    this.loading = true;
    this.adminSignin$ = this.authService.adminSignin(f.value).subscribe({
      next: (res) => {
        sessionStorage.setItem('token', res.token);
        this.authService.user = this.authService.getPayLoad();
        this.authService.lp_number = this.authService.user.lp_number;
        this.router.navigateByUrl(`/admin/dashboard`);
      },
      error: (err) => {
        this.loading = false;
        this.error = 'Wrong Username/Password';
      },
    });
  }

  ngOnDestroy(): void {
    if (this.adminSignin$) {
      this.adminSignin$.unsubscribe();
    }
  }
}
