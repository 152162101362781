<mat-progress-spinner  *ngIf="loading" color="warn" diameter="50" strokeWidth="2" mode="indeterminate">
    </mat-progress-spinner>
<section *ngIf="user">
  <h3 class="space-between"><span>LP{{ user?.lp_number }}</span><span><button mat-raised-button color="primary" (click)="registrationForm()">Registration Form</button></span></h3>
  <h3>{{ user?.name }}</h3>

  <p>Office: {{ user?.office }}, {{ user?.location }}</p>
  <p>Bank details: {{ user?.bank }},{{ user?.account_number }}</p>
  <h3>Contact details</h3>
  <p>Email: {{ user?.email }}</p>
  <p>Phone number: {{ user?.phone_number }}</p>

  <p>Registered: {{ user?.createdAt | date: "medium" }}</p>
  <p>Profile updated: {{ user?.updatedAt | date: "medium" }}</p>

  <h3>Next of Kin</h3>
  <p>Name: {{ user?.next_kin }}</p>
  <p>Phone: {{ user?.kin_phone }}</p>
</section>
<section *ngIf="user" class="space-between">
<span>
  <button mat-raised-button color="primary" (click)="changepassword(user)">
    Change password</button
  >
  </span>


  <span>
  <a class="delete" mat-button color="accent" routerLink="editProfile"
    >Edit Profile</a
  ></span>


</section>
