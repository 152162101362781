<h2>Hi, {{ user.name }}</h2>
<form #changepassword="ngForm" (ngSubmit)="changePassword(changepassword)">
  <mat-dialog-content>
    <h3>Change your password</h3>
    <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
    [attr.aria-pressed]="hide">
    <mat-icon matSuffix>{{hide ? 'visibility_off' : 'visibility'}} Show passwords</mat-icon>
    </button>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input
      [type]="hide ? 'password' : 'text'"
        name="oldpassword"
        matInput
        placeholder="Password"
        required
        [(ngModel)]="changepassword.oldpassword"
        #oldpassword="ngModel"
      />
      <mat-hint>Six characters or more</mat-hint>
   </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>New Password</mat-label>
      <input
      [type]="hide ? 'password' : 'text'"
        name="newpassword"
        matInput
        placeholder="New Password"
        required
        [(ngModel)]="changepassword.newpassword"
        #newpassword="ngModel"
      />
      <mat-hint>Six characters or more</mat-hint>
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
      --> </mat-form-field
    ><br />
    <mat-form-field appearance="outline">
      <mat-label>Confirm New Password</mat-label>
      <input
      [type]="hide ? 'password' : 'text'"
        name="confirm"
        matInput
        placeholder="Confirm New Password"
        required
        [(ngModel)]="changepassword.confirm"
        #confirm="ngModel"
      />
      <mat-hint>Six characters or more</mat-hint>

    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <span>
    <button mat-dialog-close mat-button [mat-dialog-close]  >Cancel</button>
  </span>
  <span>
    <button
      [mat-dialog-close]="true"
      mat-raised-button
      cdkFocusInitial
      color="primary"
      [disabled]="!changepassword.form.valid"
      type="submit"
    >
      Change Password
    </button>
  </span>

  </mat-dialog-actions>
</form>
