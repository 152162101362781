import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { NavigationStart, NavigationEnd, RouterEvent, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  @Input() user;
  loading: boolean;
  constructor(private authService: AuthService, private router: Router) {
    this.loading = false;
    router.events.subscribe((event: RouterEvent): void => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      } else if (event instanceof NavigationEnd) {
        this.loading = false;
      }
    });
  }

  ngOnInit(): void {

  }
}


