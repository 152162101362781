import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unique',
  pure: false
})
export class UniquePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    let uniqueItems = value.filter((el, index, array) => {
      return array.indexOf(el) === index;
    });
    
    return uniqueItems;
  }

}
