import { MaterialModule } from './../material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { ProfileComponent } from '../user/profile/profile.component';
import { FormsModule } from '@angular/forms';
import { BorrowComponent } from './borrow/borrow.component';
import { SaveComponent } from './save/save.component';
import { ErrorComponent } from './error/error.component';
import { AuthService } from '../services/auth.service';
import { GuarantorComponent } from './guarantor/guarantor.component';
import { DialogComponent } from './dialog/dialog.component';
import { PaybackComponent } from './payback/payback.component';
import { SearchPipe } from './search.pipe';
import { UniquePipe } from './unique.pipe';
import { SearchUserPipe } from './search-user.pipe';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogBoxComponent } from './dialog-box/dialog-box.component';
import { UserService } from '../services/user.service';
import { AdminService } from '../services/admin.service';
import { SortByPipe } from './sort-by.pipe';




@NgModule({
  declarations: [
    FooterComponent,
    NavbarComponent,
    ProfileComponent,
    BorrowComponent,
    SaveComponent,
    ErrorComponent,
    GuarantorComponent,
    DialogComponent,
    PaybackComponent,
    SearchPipe,
    UniquePipe,
    SearchUserPipe,
    DialogBoxComponent,
    SortByPipe,
  ],
  imports: [CommonModule, RouterModule, MaterialModule, FormsModule, MatDialogModule ],
  exports: [
    NavbarComponent,
    FooterComponent,
    ProfileComponent,
    BorrowComponent,
    GuarantorComponent,
    SaveComponent,
    PaybackComponent,
    SearchPipe,
    UniquePipe,
    SearchUserPipe,
    SortByPipe
  ],
  providers: [    {
    provide: MatDialogRef, useValue: {}
  },
  { provide: MAT_DIALOG_DATA, useValue: {} },
  AuthService, UserService, AdminService],
})
export class SharedModule {}
