import { NgForm } from '@angular/forms';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  user;
  hide = true;
  changePassword$: Subscription;
  constructor(
    private userService: UserService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.user = this.data;

  }

  changePassword(f: NgForm) {
    console.log(f.value)
    if (f.value.newpassword == f.value.confirm) {
      this.changePassword$ = this.userService
        .changePassword(f.value)
        .subscribe({
          next: (res) => {
            this.snackbar.open(`Password changed successful`, 'Ok!', {
              duration: 2000,
            });
            this.ngOnInit();
          },
          error: (e) => {
            this.snackbar.open(`There is an error`, 'Error!', {
              duration: 2000,
            });
            this.ngOnInit();
          },
        });
      this.authService.signout();
    } else {
      this.snackbar.open(`Password not same`, 'Error!', {
        duration: 2000,
      });
      this.ngOnInit();
    }
  }

  ngOnDestroy(): void {
    if (this.changePassword$) {
      this.changePassword$.unsubscribe();
    }
  }
}
