<app-navbar></app-navbar>

<section class="signup">
  <mat-card-title>You are welcome to WAEC Ikeja CT & CS</mat-card-title>

  <p>Kindly fill in the below form, we will like to have you.</p>

  <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
    <ng-template>Fill out your personal details</ng-template>

    <mat-label>First name</mat-label>
    <mat-form-field appearance="outline">
      <input
        type="text"
        name="firstName"
        matInput
        placeholder="First name"
        required
        formControlName="firstName"
      />
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>-->
      <mat-hint>e.g. OLUWAFEMI</mat-hint>
    </mat-form-field>

    <mat-label>Middle name</mat-label>
    <mat-form-field appearance="outline">
      <input
        type="text"
        name="middleName"
        matInput
        placeholder="Middle name"
        formControlName="middleName"
      />
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>-->
      <mat-hint>e.g. CHUKWU</mat-hint>
    </mat-form-field>
    <mat-label>Last name</mat-label>
    <mat-form-field appearance="outline">
      <input
        type="text"
        name="lastName"
        matInput
        placeholder="Last name"
        required
        formControlName="lastName"
      />
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
      <mat-hint>e.g. CIROMA</mat-hint>
    </mat-form-field>
    <mat-label>E-mail</mat-label>
    <mat-form-field appearance="outline">
      <input
        type="email"
        name="email"
        formControlName="email"
        matInput
        placeholder="E-mail"
        required
      />
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
      <mat-hint>e.g. chukwu@yahoo.com</mat-hint>
    </mat-form-field>
    <mat-label>Phone number</mat-label>
    <mat-form-field appearance="outline">
      <input
        type="tel"
        name="phone_number"
        formControlName="phone_number"
        matInput
        placeholder="Phone number"
        required
      />
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
    <mat-hint>LP1234</mat-hint> -->
    </mat-form-field>

    <ng-template>Fill out your Office details </ng-template>
    <mat-label>LP Number</mat-label>
    <mat-form-field appearance="outline">
      <h4 matPrefix>LP</h4>
      <input
        type="text"
        name="lp_number"
        formControlName="lp_number"
        matInput
        placeholder="LP Number"
        required
      />
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
    <mat-hint>LP1234</mat-hint> -->
    </mat-form-field>
    <mat-label>Division/Office</mat-label>
    <mat-form-field appearance="outline">
      <input
        type="text"
        name="office"
        formControlName="office"
        matInput
        placeholder="Division/Office"
        required
      />
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
    <mat-hint>LP1234</mat-hint> --> </mat-form-field
    ><br />
    <mat-label>Office Location</mat-label>
    <mat-form-field appearance="outline">
      <input
        type="text"
        name="location"
        formControlName="location"
        matInput
        placeholder="Location"
        required
      />
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
    <mat-hint>LP1234</mat-hint> -->
    </mat-form-field>

    <ng-template>Fill out your bank details</ng-template>
    <mat-label>Account number</mat-label>
    <mat-form-field appearance="outline">
      <input
        type="text"
        name="account_number"
        formControlName="account_number"
        matInput
        placeholder="Account number"
      />
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
    <mat-hint>LP1234</mat-hint> -->
    </mat-form-field>
    <mat-label>Bank</mat-label>
    <mat-form-field appearance="outline">
      <input
        type="text"
        name="bank"
        formControlName="bank"
        matInput
        placeholder="Bank"
      />
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
    <mat-hint>LP1234</mat-hint> -->
    </mat-form-field>

    <ng-template
      >Fill out your password. Do not share this with anyone.
    </ng-template>
    <mat-label>Password</mat-label>
    <mat-form-field appearance="outline">
      <input
        type="password"
        name="password"
        autocomplete="current-password"
        formControlName="password"
        matInput
        placeholder="Password"
        required
      />
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
    <mat-hint>Password</mat-hint>  -->
    </mat-form-field>
    <mat-label>Confirm Password</mat-label>
    <mat-form-field appearance="outline">
      <input
        type="password"
        name="confirmpassword"
        autocomplete="current-password"
        formControlName="confirmpassword"
        matInput
        placeholder="Confirm password"
        required
      />
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
    <mat-hint>Password</mat-hint>  -->
    </mat-form-field>
    <ng-template>Register your Next of Kin details</ng-template>
    <mat-label>Next of Kin: Full name</mat-label>
    <mat-form-field appearance="outline">
      <input
        type="text"
        name="next_kin"
        formControlName="next_kin"
        matInput
        placeholder="Next of kin: Full name"
        required
      />
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
    <mat-hint>Password</mat-hint>  -->
    </mat-form-field>
    <mat-label>Next of Kin: Phone number</mat-label>
    <mat-form-field appearance="outline">
      <input
        type="tel"
        name="kin_phone"
        formControlName="kin_phone"
        matInput
        placeholder="Next of kin: Phone number"
        required
      />
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
    <mat-hint>Password</mat-hint>  -->
    </mat-form-field>

    <ng-template>Done. Confirm your details</ng-template>

    <button
      mat-raised-button
      color="primary"
      [disabled]="signUpForm.invalid"
      type="submit"
    >
      Sign Up
      <mat-progress-spinner
        *ngIf="loading"
        color="warn"
        diameter="20"
        strokeWidth="2"
        mode="indeterminate"
      >
      </mat-progress-spinner>
    </button>
  </form>
</section>
