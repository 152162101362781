
<mat-dialog-content>
  <h2 class="space-between">- Final Withdrawal</h2>
  <p></p>

  </mat-dialog-content>


  <mat-dialog-actions class="space-between">

    <button mat-button mat-dialog-close color="warn">Cancel</button>
    <button mat-raised-button  color="primary" [mat-dialog-close]='true' cdkFocusInitial>Confirm</button>

  </mat-dialog-actions>
