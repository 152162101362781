
<section>
<mat-dialog-content *ngIf="data.payment_duration">
<div>I, <span class='bold'>{{user?.name}} </span>with <span class='bold'>LP{{user?.lp_number}}</span> hereby agree to the terms and condition of the Co-Operative society.</div>
      <p><span>Loan amount</span><span class='bold'>{{data?.amount | currency:'NGN':'symbol-narrow'}}</span></p>
      <p><span>Interest :</span><span class='bold'>{{interest | currency:'NGN':'symbol-narrow'}}</span></p>
      <hr/>
      <p><span>Total deduction:</span><span  class='bold'>{{totalDeduction | currency:'NGN':'symbol-narrow'}}</span> </p>
      <p><span>Monthly deductions:</span><span  class='bold'>{{monthlyDeduction | currency:'NGN':'symbol-narrow'}}</span> </p>

</mat-dialog-content>

<mat-dialog-content *ngIf="data.allowance_type">
<div>I, <span class='bold'>{{user?.name}} </span>with <span class='bold'>LP{{user?.lp_number}}</span> hereby agree to the terms and condition of the Co-Operative society.</div>
      <p><span>Loan amount</span><span class='bold'>{{data?.amount | currency:'NGN':'symbol-narrow'}}</span></p>
      <p><span>Interest :</span><span class='bold'>{{interest | currency:'NGN':'symbol-narrow'}}</span></p>
      <p><span>Allowance for Repayment:</span><span  class='bold'>{{data.allowance_type}}</span> </p>
      <hr/>
        <p><span>Total deduction:</span><span  class='bold'>{{totalDeduction | currency:'NGN':'symbol-narrow'}}</span> </p>

</mat-dialog-content>
<hr/>
<mat-dialog-actions align='end'>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" [mat-dialog-close]='true' cdkFocusInitial>Confirm</button>
</mat-dialog-actions>
</section>
