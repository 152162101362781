
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  //private API = 'http://localhost:5000/api/user';
  private API = 'https://cooperativebox.herokuapp.com/api/user';
  lp_number = '';
  user: any;
  constructor(
    private http: HttpClient,
    private authService: AuthService, private router: Router
  ) {
    this.user = this.authService.getPayLoad();
    this.lp_number = this.user.lp_number;
  }

  loanRequest(data) {
    return this.http.post(`${this.API}/${this.lp_number}/loan`, data);
  }
  confirmGuarantor(data) {
    return this.http
      .patch(`${this.API}/${this.lp_number}/guarantor/${data.loan_id}`, data)

  }

  specialLoanRequest(data) {

    return this.http.post(`${this.API}/${this.lp_number}/specialLoan`, data);
  }

  getPendingSpecials(): Observable<any> {
    return this.http.get(`${this.API}/${this.lp_number}/pending`);
  }

  getOngoingLoans(): Observable<any> {
    return this.http.get(`${this.API}/${this.lp_number}/ongoingloan`);
  }
  getUser(lp_number = this.lp_number): Observable<any> {
    return this.http.get(`${this.API}/${lp_number}`);
  }
  getTransactions(lp_number = this.lp_number): Observable<any> {
    return this.http.get(`${this.API}/${lp_number}/confirmed`);
  }

  getPendingTransactions(): Observable<any> {
    return this.http.get(`${this.API}/${this.lp_number}/pending`);
  }

  changePassword(data): Observable<any> {
    return this.http.patch(
      `${this.API}/${this.lp_number}/changepassword`,
      data
    );
  }

  editProfile(data): Observable<any> {
    return this.http.patch(`${this.API}/${this.lp_number}`, data);
  }

  getItems(): Observable<any> {
    return this.http.get(`${this.API}/${this.lp_number}/items`);
  }

  createSales(items): Observable<any> {
    return this.http.post(`${this.API}/${this.lp_number}/sales`, items);
  }

  getPendingSales() {
    return this.http.get(`${this.API}/${this.lp_number}/sales?confirmed=false`);
  }

  createSave(save) {
    return this.http.post(`${this.API}/${this.lp_number}/save`, save);
  }

  deleteSave(saveId){
    return this.http.delete(`${this.API}/${this.lp_number}/save/${saveId}`);
  }

  withdrawSavings(save) {

    return this.http.post(`${this.API}/${this.lp_number}/withdraw`, save);
  }

  deleteLoan(loan_id){
    return this.http.delete(`${this.API}/${this.lp_number}/loan/${loan_id}`);
  }

  deleteSpecial(loan_id){
    return this.http.delete(`${this.API}/${this.lp_number}/specialLoan/${loan_id}`);
  }

  finalWithdraw(){
    return this.http.get(`${this.API}/${this.lp_number}/finalWithdraw`);
  }

  createFinalWithdrawal(userDetails){
    return this.http.post(`${this.API}/${this.lp_number}/finalWithdraw`, userDetails);
  }

  checkFinalWithdrawal(lp_number){
    return this.http.get(`${this.API}/${lp_number}/finalWithdraw/check`);
  }




}
