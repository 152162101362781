
<mat-dialog-content>
<h2 class="space-between"><span>{{data.amount| currency:'NGN':'symbol-narrow'}}</span><span>LP{{data.lp_number}}</span></h2>
<h3>I, hereby agree to guarantee LP{{data.lp_number}} with the amount of {{data.amount| currency:'NGN':'symbol-narrow'}}.</h3>
<h3 *ngIf="data.payment_duration">*To pay within {{data.payment_duration}} months.</h3>
<h3 *ngIf="data.allowance_type">*To pay with the next {{data.allowance_type}} allowance.</h3>
<h3>I will payback the amount if he/she above refuses to repay the amount as at when due.</h3>
</mat-dialog-content>


<mat-dialog-actions class="space-between">

  <button mat-button mat-dialog-close color="warn">Cancel</button>
  <button mat-raised-button  color="primary" [mat-dialog-close]='true' cdkFocusInitial>Confirm</button>

</mat-dialog-actions>
