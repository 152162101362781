import { ConfirmLoanComponent } from './user/confirm-loan/confirm-loan.component';
import { ChangePasswordComponent } from './user/change-password/change-password.component'
import { DialogComponent } from './shared/dialog/dialog.component';
import { RouterModule } from '@angular/router';
import { TokenInterceptor } from './services/token.interceptor';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminComponent } from './admin/admin.component';
import { UserComponent } from './user/user.component';
import { PolicyComponent } from './policy/policy.component';
import { ContactComponent } from './contact/contact.component';
import { BoardsComponent } from './boards/boards.component';
import { HomeComponent } from './home/home.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';



// Angular Material Modules
import {MaterialModule} from './material/material.module';
import { AuthService } from './services/auth.service';
import { JwtConfig, JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { ExcelService } from './services/excel.service';
import { UserService } from './services/user.service';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeductionFormComponent } from './admin/deduction-form/deduction-form.component';
import { AdminService } from './services/admin.service';


export const getToken = () => {
  return sessionStorage.getItem('token');
}
@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    PolicyComponent,
    ContactComponent,
    BoardsComponent,
    HomeComponent,
    SignInComponent,
    SignUpComponent
  ],
  entryComponents: [DialogComponent, DeductionFormComponent,  ChangePasswordComponent, ConfirmLoanComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    MaterialModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken
      }
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    JwtHelperService,
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { provide: MAT_DIALOG_DATA, useValue: {} }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
