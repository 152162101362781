<section class="signin">
  <mat-card>
    <mat-card-title> Sign in as Administrator </mat-card-title>
  	<mat-card-content>{{error}}</mat-card-content>
    <form
      class="signform"
      #adminsignin="ngForm"
      (ngSubmit)="adminSubmit(adminsignin)"
    >
      <mat-label>E-mail</mat-label>
      <mat-form-field appearance="outline">
        <input
          type="email"
          name="email"
          ngModel
          matInput
          placeholder="E-mail"
          required
          #email="ngModel"
        />
        <mat-hint>abc@xyz.com</mat-hint>
        <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
     --> </mat-form-field
      ><br />
      <mat-label>Password</mat-label>
      <mat-form-field appearance="outline">
        <input
          [type]="hide ? 'password' : 'text'"
          name="password"
          ngModel
          matInput
          placeholder="password"
          required
          #password="ngModel"
        />
        <button
          mat-icon-button
          matSuffix
          type="button"
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
        <mat-hint>Six characters or more</mat-hint>
        <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
      --> </mat-form-field
      ><br />

      <button
        mat-raised-button
        color="primary"
        [disabled]="!adminsignin.form.valid"
        type="submit"
      >
        Sign in
        <mat-progress-spinner
          *ngIf="loading"
          color="warn"
          diameter="20"
          strokeWidth="2"
          mode="indeterminate"
        ></mat-progress-spinner>
      </button>
    </form>
  </mat-card>
</section>
