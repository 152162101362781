<form #deduction="ngForm" (ngSubmit)="ngSubmit(deduction)">
  <h2>Add Deduction</h2>
  <mat-dialog-content>

    <mat-label>Amount</mat-label>
    <mat-form-field appearance="outline">
      <span matPrefix>₦&nbsp;</span>
      <span matSuffix>.00</span>
      <input type="number" name="amount" matInput placeholder="Deduction amount" required [(ngModel)]="deduction.amount"
        #amount="ngModel">
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions class="space-between">

  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" [mat-dialog-close]='true' cdkFocusInitial type="submit">Add
    Deduction</button>

  </mat-dialog-actions>
</form>
