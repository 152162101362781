<mat-card>
<mat-card-title class="space-between">
 <span>LP{{guarantor.lp_number}}</span><span>{{guarantor.amount| currency:'NGN':'symbol-narrow'}}</span>
</mat-card-title>

<mat-card-content>
  <p>{{guarantor.name}}</p>
  <p><mat-checkbox [checked]="guarantor.gurarantor1_confirmed" [disabled]="disabled">LP{{guarantor.guarantor1_lp}}</mat-checkbox></p>
  <p><mat-checkbox [checked]="guarantor.guarantor2_confirmed" [disabled]="disabled">LP{{guarantor.guarantor2_lp}}</mat-checkbox></p>
</mat-card-content>
<mat-card-footer>
<span>{{guarantor.createdAt |date:'medium' }}</span>
</mat-card-footer>
</mat-card>
