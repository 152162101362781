<mat-toolbar color='primary'>
<span>
<a [routerLink]="['/']"><img src="../../../assets/icon.png"/><h3> Ikeja CT & CS</h3></a>
<button mat-button [routerLink]="['/user', lp_number]" *ngIf="user"  routerLinkActive="active" >{{lp_number}}</button>
</span>

<span>

<button mat-button [routerLink]="['/signin']" *ngIf="!user"  routerLinkActive="active">Sign in</button>
<button mat-button [routerLink]="['/signup']" *ngIf="!user" routerLinkActive="active" color="accent">Sign Up</button>
</span>
</mat-toolbar>
