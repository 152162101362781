import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { User } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { MatSnackBar } from '@angular/material/snack-bar';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  user: User;
  active = true;
  loading = true;
  getUser$: Subscription;
  docDefinition;
  constructor(private userService: UserService, public dialog: MatDialog, private snackbar: MatSnackBar) {}

  ngOnInit(): void {
    this.getUser$ = this.userService.getUser().subscribe({
      next: (res: any) => {
        this.user = res.user ;
        this.loading = false;
      },
      error: (err) => console.log(err),
    });
  }

  changepassword(data): void {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '400px',
      data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {


      } else {
        this.snackbar.open('Password changed cancelled', 'Ok!',{ duration: 2000});

      }
    });
  }

  registrationForm() {

    this.docDefinition = {
      content: [
        {
          text: 'THE WEST AFRICAN EXAMINATIONS COUNCIL',
          fontSize: 20,
          alignment: 'center',
          bold: true,
          margin: [10, 20, 0, 10],
        },
        {
          text: 'PMB NO. 21582, IKEJA, LAGOS',
          fontSize: 10,
          alignment: 'center',
          margin: [0, 0, 0, 0],
        },
        {
          text: 'IKEJA STAFF CO-OPERATIVE AND CREDIT SOCIETY LTD',
          fontSize: 16,
          alignment: 'center',
          bold: true,
          margin: [20, 20, 0, 10],
        },
        {
          text: `${new Date().toLocaleDateString()} `,
          fontSize: 12,
          alignment: 'right',
          margin: [10, 20, 0, 10],
        },
        {
          text: `I, ${this.user?.name} with LP${this.user?.lp_number} hereby authorize the WAEC Account Department to deduct the sum of ₦__________________ from my monthly salary with effects from the month of _____________ until further notice; and to pay such deductions to the account of WAEC IKEJA CT & CS Limited.


          Below are my details for further clarification.

          LP number: LP${this.user?.lp_number}.
          Name: ${this.user?.name}.
          Office: ${this.user?.office}.
          Location: ${this.user?.location}.
          Signature: _______________________
          Phone number: ${this.user?.phone_number}.



          Endorsement by:IKEJA CT & CS Treasurer:

          Signature & Date:___________________`,
          margin: [10, 10, 10, 10],
          fontSize: 16,
        },
        {
          text: `Confirmed & Approved by Ikeja CT & CS(Treasurer)`,
        },
      ],
    };

      pdfMake
        .createPdf(this.docDefinition)
        .download(`LP ${this.user?.lp_number} Registration form`);

  }

  ngOnDestroy(): void {
    this.getUser$.unsubscribe();
  }
}
