import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
//private API = 'http://localhost:5000/api/admin';
private API = 'https://cooperativebox.herokuapp.com/api/admin';
  constructor(
    private http: HttpClient,
    private router: Router,
    private auth: AuthService
  ) {}

  changeRole(lp_number, role) {
    return this.http.patch(`${this.API}/changeRole`, {lp_number, role});
  }
  confirmLoans(){
    return this.http.patch(`${this.API}/loans`, 'confirmed');
  }

  deleteLoans(){
    return this.http.delete(`${this.API}/loans`);
  }

  deleteALoan(data){
    return this.http.delete(`${this.API}/loans/${data.loan_id}`);
  }

  deleteASpecial(data){
    return this.http.delete(`${this.API}/specialLoan/${data.loan_id}`);
  }

  getAllTransactions(): Observable<any> {
    return this.http.get(`${this.API}/confirmed`);
  }
  getPendingTransactions(): Observable<any> {
    return this.http.get(`${this.API}/pending`);
  }

  getUsers(): Observable<any> {
    return this.http.get(`${this.API}/users`);
  }

  createTransactions(data): Observable<any> {
    return this.http.post(`${this.API}/exceltransactions`, data);
  }

  openingTransactions(data): Observable<any> {
    return this.http.post(`${this.API}/openingTransactions`, data)
  }

  deleteUser(lp_number){
    return this.http.delete(`${this.API}/users/${lp_number}`);
  }

  deleteDeductions(unconfirmed){
    return this.http.delete(`${this.API}/deduction?confirmed=${unconfirmed}`);
  }

  deletedTransaction(data){
    return this.http.delete(`${this.API}/exceltransactions/delete?fromDate=${data.fromDate}&toDate=${data.toDate}`);
  }

  deleteSaves() {
    return this.http.delete(`${this.API}/save`);
  }

  confirmSaves(): Observable<any> {
    return this.http.patch(`${this.API}/save`, 'confirmed');
  }

  confirmPaybacks(): Observable<any> {
    return this.http.patch(`${this.API}/payback`, 'confirmed');
  }

  deletePaybacks(): Observable<any> {
    return this.http.delete(`${this.API}/payback`);
  }

  confirmLoan(data): Observable<any> {
    return this.http.patch(`${this.API}/loan/${data.loan_id}`, data);
  }

  confirmSpecialLoan(data): Observable<any> {
    return this.http.patch(`${this.API}/specialLoan/${data.loan_id}`, data);
  }

  getDeduction(loan_id): Observable<any> {
    return this.http.get(`${this.API}/payback/${loan_id}`);
  }

  createItem(item): Observable<any> {
    return this.http.post(`${this.API}/items`, item);
  }

  getItems() {
    return this.http.get(`${this.API}/items`);
  }

  deleteItem(itemId): Observable<any> {
    return this.http.delete(`${this.API}/items/${itemId}`);
  }
  createDeduction(deduction) {
    return this.http.post(`${this.API}/deduction/loan`, deduction);
  }

  createSpecialDeduction(deduction) {
    return this.http.post(`${this.API}/deduction/special`, deduction);
  }

  getALoan(loan_id) {
    return this.http.get(`${this.API}/loan/${loan_id}`);
  }

  getUser(lp_number): Observable<any> {
    return this.http.get(`${this.API}/users/${lp_number}`);
  }

  getStatus(){
    return this.http.get(`${this.API}/getstatus`);
  }

  confirmSale(sale) {
    return this.http.patch(`${this.API}/sales/${sale.id}`, sale);
  }

  getConfirmedSales() {
    return this.http.get(`${this.API}/sales`);
  }

  getTransactionsReport(start, end) {
    return this.http.get(`${this.API}/reports?start=${start}&end=${end}`);
  }

  confirmDeduction(loan_id, deduction_id) {
    return this.http.patch(`${this.API}/deduction/loan/${loan_id}`, {
      deduction_id,
    });
  }

  resetPassword(user_id) {
    return this.http.patch(`${this.API}/resetpassword`, { user_id });
  }

  getExpenses(){
    return this.http.get(`${this.API}/expenses`)
  }

  createExpense(expense){
    return this.http.post(`${this.API}/expenses`, expense)
  }

  recalculate(loan){
    return this.http.post(`${this.API}/recalculate`, loan)
  }

  savingsToDeduction(item): Observable<Object>{
    return this.http.post(`${this.API}/savingsdeduction/${item.loan.loan_id}`, item);
  }

  finalWithdrawal(){
    return this.http.get(`${this.API}/finalWithdraw`);
  }

  deleteFinalWithdrawal(final_id){
    return this.http.delete(`${this.API}/finalWithdraw/${final_id}`);
  }

  confirmFinalWithdrawal(final_id){
    return this.http.patch(`${this.API}/finalWithdraw/${final_id}/confirm`, {confirmed: true});
  }



}
