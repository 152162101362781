import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchUser'
})
export class SearchUserPipe implements PipeTransform {

  transform(list: any[], searchItem: string): any {
    return list
      ? list.filter(
          (item: any) =>
            item.lp_number.match(String(searchItem)) ||  item.user.match(String(searchItem))
        )
      : [];
  }

}
